<template>
  <v-card
    max-width="400"
    class="mx-auto"
  >
  <v-container>
    <v-row
      dense
    >
      <v-col cols="12">
        <v-card color="385F73">
          <v-img
            :src="require(`@/assets/shot/card-top.jpg`)"
            class="white--text align-end"
            height="200px"
          >
            <v-card-title class="text-h5 justify-end">Shot Club</v-card-title>
          </v-img>
          <v-card-text
            v-if="this.member.id"
          >
            <v-row
              align="center"
            >
              <vue-barcode
                :value="member.id"
                class="mx-auto"
              >
              </vue-barcode>
            </v-row>
          </v-card-text>
          <v-card-title v-if="this.member.id" class="justify-center">{{ member.line_name }}</v-card-title>
          <v-card-title v-if="this.member.id" class="justify-center">来店ポイント: {{ (visitPointList.available_point - visitPointList.redemption_points) }} pt <br> (今月末失効ポイント: {{ visitPointList.expiration_points }} pt)</v-card-title>
          <v-card-title v-if="this.member.id" class="justify-center">コイン: {{ member.point || '0' }} pt</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </v-card>
</template>

<script>
import VueBarcode from 'vue-barcode'

export default {
  components: {
    VueBarcode
  },

  data: () => ({
    loading: false,
    selection:  1,
    title: "会員証",
    member: {},
    visitPointList: {},
  }),

  props: {
    lineAuth: String
  },

  created() {
    let api_url = process.env.VUE_APP_ADMIN_URL+'/api/gambler/'+this.lineAuth
    if (process.env.NODE_ENV != 'production') {
      api_url = process.env.VUE_APP_ADMIN_URL+'/api/local-gambler/'+this.lineAuth
    }
    this.axios.get(api_url, {line: this.lineAuth})
      .then( (response) => {
        console.log(response)
        this.member = response.data.gambler
        this.visitPointList = response.data.visitPointList
        console.log(this.visitPointList)
      })
      .catch( (err) => {
        console.log(err)
      })
  },

  methods: {
    reserve() {
      this.loading = true
      setTimeout(() => (this.loading = false), 2000)
    }
  }
}
</script>
